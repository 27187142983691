import React, { useState, useContext, useEffect, useCallback } from 'react';
import * as S from './AnswerInputV2.styles';
import useLocalStorage from '../../../hooks/useLocalStorage';
import FunnelContext from '../../../context/FunnelContext';

const AnswerInput = props => {
  const { answers, setAnswers, extraObjects, setExtraObjects } = useContext(
    FunnelContext
  );

  const [answersLS, setAnswersLS] = useLocalStorage('answers', {});

  const [defaultValue, setDefaultValue] = useState(
    answersLS[props.name] || props.defaultValue
  );
  const [defaultValueLoaded, setDefaultValueLoaded] = useState(false);

  const handleChange = event => {
    const limit = 2;
    handleValue(parseInt(event.target.value.slice(0, limit)));
  };

  const handleValue = useCallback(
    value => {
      setDefaultValue(value);
      const newAnswers = Object.assign(answers, {
        [props.name]: value
      });
      setAnswers(newAnswers);
      if (isNaN(value)) {
        setAnswers({ ...answers, [props.name]: 0 });
      }
      if (props.addToExtraObjects) {
        const newExtraObjects = Object.assign(extraObjects, {
          [props.name]: value
        });
        setExtraObjects(newExtraObjects);
      }
      const newAnswersLS = Object.assign(answersLS, {
        [props.name]: value
      });
      setAnswersLS(newAnswersLS);
    },
    [
      props,
      answers,
      answersLS,
      extraObjects,
      setAnswers,
      setAnswersLS,
      setExtraObjects
    ]
  );

  useEffect(() => {
    if (!defaultValueLoaded) {
      handleValue(defaultValue);
      setDefaultValueLoaded(true);
    }
  }, [defaultValue, handleValue, defaultValueLoaded, setDefaultValueLoaded]);

  const onClickMinus = () => {
    const currentValue = answersLS[props.name] || 0;
    const value = parseInt(currentValue) - 1;
    const _value = value < 0 ? 0 : value;
    handleValue(_value);
  };

  const onClickPlus = () => {
    const currentValue = answersLS[props.name] || 0;
    if (currentValue < 99) {
      const value = parseInt(currentValue) + 1;
      handleValue(value);
    } else {
      return currentValue;
    }
  };

  return (
    <S.Container>
      <S.MinusButton onClick={onClickMinus}>-</S.MinusButton>
      <S.Input
        key={props.name}
        name={props.name}
        placeholder={props.placeholder}
        type={props.type}
        value={defaultValue}
        required={props.required}
        onChange={handleChange}
      />
      <S.Label>Years</S.Label>
      <S.PlusButton onClick={onClickPlus}>+</S.PlusButton>
    </S.Container>
  );
};

export default AnswerInput;
